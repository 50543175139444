import React from 'react';
import InvoiceHeader from './InvoiceHeader';
import TotalAmount from './TotalAmount';
import { ReviewOrderStyle } from '../../pages/order/Review';
import InvoiceFooter from './InvoiceFooter';
import { printType } from '../../constants/orderStatus';
import AdditionalChargeList from './AdditionalChargeList';

const InvoiceToPrint = React.forwardRef(
  ({ order, type = printType.INVOICE }, ref) => (
    <div className="invoice-container" ref={ref}>
      <div className="invoice-content">
        <InvoiceHeader order={order} type={type} />
        <div style={{ marginTop: '30px' }} />
        <div className="order-product-items">
          {order?.orderstyles?.map(orderStyle => (
            <ReviewOrderStyle key={orderStyle.id} orderStyle={orderStyle} />
          ))}
        </div>
        {order?.orderadditionalcharges?.length > 0 && (
          <AdditionalChargeList
            orderAdditionalCharges={order.orderadditionalcharges}
          />
        )}
        <TotalAmount order={order} />
        <InvoiceFooter type={type} />
      </div>
    </div>
  ),
);

export default InvoiceToPrint;
